import React from "react"
import { Router } from "@reach/router"

import styled from "@emotion/styled"
import { Global, css } from "@emotion/react"

import Layout from "../components/layout"
import ConcertHistory from "../components/concertHistory"
import Concert from "../components/concert"
import Footer from "../components/footer"
import {color, spacing, screen} from "../constants"

const Konzerte = () => {
  return (
    <Layout title="Vergangene Konzerte">
      <main css={css`
        background-color: ${color.light};
        flex: 1 1 980px;
        
        padding: ${spacing.xs};
        margin: 0;

        @media (min-width: ${screen.xsPhone}) {
          //padding: ${spacing.medium};
          padding: ${spacing.big};
          margin: 0 ${spacing.small};
        }
        @media (min-width: ${screen.tablet}) {
          padding: ${spacing.big};
          margin: 0 ${spacing.medium};
        }
      `}>
        <Router basepath="/konzerte">
          <Concert path="/:cid" />
          <ConcertHistory path="/" />
        </Router>
        <Footer marginTop={true} />
      </main>
    </Layout>
  )
}

export default Konzerte